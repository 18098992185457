import React from "react"
import PropTypes from "prop-types"
import { BLOCKS, MARKS, INLINES } from "@contentful/rich-text-types"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import CommonConstant from '../../shared/constants/common-constants';
// import { useStaticQuery, graphql } from "gatsby"
import Img from 'gatsby-image'
import "./articles-detail.scss"

function ArticlesDetail({ data }) {
  // const socialdata = useStaticQuery(graphql`
  // query ArticlesDetailQuery {
  //   allContentfulSocialIconList {
  //     nodes {
  //       name
  //       contents {
  //         socialUrl
  //         socialIcon {
  //           title
  //           fluid {
  //             ...GatsbyContentfulFluid_withWebp
  //           }
  //         }
  //       }
  //       node_locale
  //     }
  //   }
  // }
  // `)
  // const socialShares = socialdata.allContentfulSocialIconList.nodes.filter(node => node.node_locale.search(currentLanguage) > -1)[1];

  const Bold = ({ children }) => <span className="bold">{children}</span>
  // const Text = ({ children }) => <p className="align-center">{children}</p>
  const website_url = CommonConstant.Domain;
  const options = {
    renderMark: {
      [MARKS.BOLD]: text => <Bold>{text}</Bold>,
    },
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => <p className={'text-comvita-copy-brown'}>{children}</p>,
      [BLOCKS.EMBEDDED_ASSET]: (node) => {
        const { title, description, file } = node.data.target.fields;
        const mimeType = file['en-US'].contentType
        const mimeGroup = mimeType.split('/')[0]
        switch (mimeGroup) {
          case 'image':
            return <img
              title={title ? title['en-US'] : null}
              alt={description ? description['en-US'] : null}
              src={file['en-US'].url}
            />
          case 'application':
            return <a
              alt={description ? description['en-US'] : null}
              href={file['en-US'].url}
            >{title ? title['en-US'] : file['en-US'].details.fileName}
            </a>
          case 'video':
            return <video className="articles-video-block" controls height="auto">
              <source src={file['en-US'].url} type="video/mp4" />
            </video>
          default:
            return <span style={{ backgroundColor: 'red', color: 'white' }}> {mimeType} embedded asset </span>
        }
      },
      [BLOCKS.HR]: (node, children) => <hr className={'comvita-hr'}></hr>,
      [BLOCKS.HEADING_1]: (node, children) => <h1 className={'text-uppercase text-comvita-copy-brown text-comvita-lighter'}>{children}</h1>,
      [BLOCKS.HEADING_2]: (node, children) => <h2 className={'text-uppercase text-comvita-copy-brown text-comvita-lighter'}>{children}</h2>,
      [BLOCKS.HEADING_3]: (node, children) => <h3 className={'text-comvita-gold display-3'}>{children}</h3>,
      [BLOCKS.HEADING_4]: (node, children) => <h4 className={'text-uppercase text-comvita-dark-brown'}>{children}</h4>,
      [BLOCKS.UL_LIST]: (node, children) => <ul className={'comvita-unordered-list'}>{children}</ul>,
      [BLOCKS.LIST_ITEM]: (node, children) => <li className={'comvita-li-list-item'}>{children}</li>,
      [INLINES.HYPERLINK]: (node) => {
        return <a href={node.data.uri} target={`${node.data.uri.startsWith(website_url) ? '_self' : '_blank'}`} rel={`${node.data.uri.startsWith(website_url) ? '' : 'noopener noreferrer'}`}>{node.content[0].value}</a>;
      }
    },
  }

  if (data) {
    return (
      <div className="articles-detail mt-5">
        <h1 className="text-comvita-dark-brown articles-detail--title mb-4">{data.title}</h1>
        <div className="text-comvita-copy-brown articles-detail--published-date mb-4">
          {data.publishedDate}
        </div>
        <div className="articles-detail--image">
          <Img
            className="mb-0 p-2 img-fluid comvita-cta-image"
            alt={data.title}
            fluid={data.primaryImage.fluid}
          />
        </div>
        <div className="articles-detail--body">
          {documentToReactComponents(data.body.json, options)}
        </div>
        {/* <div className="articles-detail--social-share d-flex align-items-center">
          <span className="text-comvita-dark-brown mr-2">Share with a friend</span>
          {socialShares.contents.map((soc, index) => (
            <a target="_blank" href={soc.socialUrl} key={index} className="mx-2">
              <Img
                className="mb-0 p-2 img-fluid"
                alt={soc.socialIcon.title}
                fluid={soc.socialIcon.fluid}
              />
            </a>

          ))}
        </div> */}
      </div>
    )
  }
}

ArticlesDetail.propTypes = {
  siteTitle: PropTypes.string,
}

ArticlesDetail.defaultProps = {
  siteTitle: ``,
}

export default ArticlesDetail
