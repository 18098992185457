import React from 'react'
import { graphql } from 'gatsby'

import 'bootstrap/dist/css/bootstrap.min.css';

import Layout from "../components/layout"
import SEO from "../components/seo"

import "../styles/style.scss"
import "../styles/main.scss"

import ArticlesDetail from '../components/articles-detail/articles-detail';
import ArticlesList from '../components/articles-list/articles-list';
import ArticlesBreadcrumb from '../components/articles-breadcrumb/articles-breadcrumb';
import {ArticlesListModel} from '../shared/models/articles-list.model'

class ArticlesDetailPage extends React.Component {

  render() {
    let articlesListTitle = this.props.data.allContentfulSlotArticlesArticleList.nodes[0].title;
    let article = this.props.data.allContentfulSlotArticlesArticle.nodes[0];

    let artcilesListModel = {};
    artcilesListModel.sectionId = "details-articles";
    artcilesListModel = this.props.data.allContentfulSlotArticlesArticleList.nodes[0];
    artcilesListModel.showLink = true;
    artcilesListModel.showPublishedDate = true;
    let articlesList = new ArticlesListModel(artcilesListModel);


    return (
      <Layout bannerImage={article.headerImage} pageTitle={article.title}>
        <SEO title={article.title} pathname={article.slug} keywords={article.title} />
        <section id="articles-detail">
         
          <div className="container">
            <ArticlesBreadcrumb data={article} locale={article.node_locale}></ArticlesBreadcrumb>
            <ArticlesDetail data={article}></ArticlesDetail>
            <hr className="text-comvita-warmer-grey comvita-summary-separator" />
            <section className="mt-5">
              <h3 className="text-comvita-dark-brown text-center">{articlesListTitle}</h3>
              <ArticlesList data={articlesList}></ArticlesList>
            </section>
          </div>
        </section>
      </Layout >
    )
  }
}

export default ArticlesDetailPage

export const pageQuery = graphql`
query NewsAndEventsDetailTemplateQuery($locale: String!, $slug: String!) {
  allContentfulSlotArticlesArticle(filter:  {slug: {eq: $slug }, node_locale: {eq: $locale}}) {
    nodes {
      title
      node_locale
      slug
        publishedDate(formatString: "DD MMMM YYYY")
        title
        id
        slug
        socialShares {
          socialUrl
          socialIcon {
            fluid {
              ...GatsbyContentfulFluid_withWebp
            }
          }
        }
        body {
          json
          nodeType
        }
        primaryImage {
          fluid {
            ...GatsbyContentfulFluid_withWebp
          }
        }
      }
    }
    allContentfulSlotArticlesArticleList(filter: {slug: {eq: "news-events-footer"}, node_locale: {eq: $locale}}, limit: 3) {
      nodes {
        id
        showLink
        showPublishedDate
        numberToShow
        title
        articles {
          publishedDate(formatString: "DD MMMM YYYY")
          title
          slug
          primaryImage {
            fluid {
              ...GatsbyContentfulFluid_withWebp
            }
          }
        }
        slug
      }
    }
}

`
