import { TypeName } from './page-slot'

export class ArticlesListModel extends TypeName {
  constructor(item = null) {
    super(item);
    this.sectionId = item.sectionId
    this.showLink = item.showLink;
    this.showPublishedDate = item.showPublishedDate;
    this.numberToShow = item.numberToShow;
    this.title = item.title;
    this.positionOfTitle = item.positionOfTitle;
    this.showTitle = item.showTitle;
    this.articles = item.articles.map((block) => {
      return new ArticleModel(block);
    })
  }
}

export class ArticleModel {
  constructor(item = null) {
    this.primaryImage = {};
    if (item) {
      this.title = item.title;
      this.slug = item.slug;
      this.primaryImage.fluid = item.primaryImage.fluid;
      this.publishedDate = item.publishedDate;
    }
  }
}