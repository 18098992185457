import React from "react"
import { LinkLocale } from "../link-locale/link-locale"
import "./acticles-breadcrumb.scss"

function ArticlesBreadcrumb({ data , locale}) {
  const articles = data;
  return (
    <div className="articles-breadcrumb mt-5">
      <LinkLocale url={`/`} >
        <span className="breadcrumb-name">{locale==='en-US' ? 'HOME':'主页'}</span>
        <span className="breadcrumb-sep">/</span>
      </LinkLocale>
      <LinkLocale url={`/news-and-events`} >
        <span className="breadcrumb-name">{locale==='en-US' ? 'ARTICLES':'文章'}</span>
      </LinkLocale>
      {
        articles &&
        <LinkLocale url={`/${data.slug}`} >
          <span className="breadcrumb-sep">/</span>
           <span className="breadcrumb-name text-comvita-dark-brown">{data.title}</span>
        </LinkLocale>
      }
    </div>
  )
}

export default ArticlesBreadcrumb
